import { N } from '@angular/cdk/keycodes';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'precisionNumber'
})
export class PrecisionNumberPipe implements PipeTransform {

    transform(number: number, args?: any): any {

        if (isNaN(number)) return null; // will only work value is a number
        if (number === null) return null;
        if (number === 0) return null;

        let abs = Math.abs(number);

        var decimals = 2;

        if (abs >= 100)
            decimals = 0;
        else if (abs >= 10)
            decimals = 2;
        else if (abs >= 0.1)
            decimals = 4;
        else if (abs >= 0.01)
            decimals = 5;
        else
            decimals = 6;

        //Las culturas españolas solo ponen separador de miles si pasan de 9.999
        let result = abs.toLocaleString('de-DE',{ minimumFractionDigits: decimals, maximumFractionDigits: decimals });

        return result;
    }
}