import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  _url:string = this._config.host;

  constructor(private _http: HttpClient, private _config:ConfigService) { }

  get isValid() {

    if (localStorage.getItem('token')) {
      return true;
    }

    console.log("Invalid token");
    return false;

  }

  get token() {
    let token = localStorage.getItem('token');

    return token;

  }

  get decode() {
    let token = localStorage.getItem('token');

    if (token != null || token != undefined)
      return jwt_decode(token);
    else
      return {};

  }

  //login
  validate(username, password) {


    let url = `${this._url}auth/login`;

    let content = {
      username: username,
      password: password,
    };

    return this._http.post(url, content);
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  register(data) {

    let url = `${this._url}auth/register`;

    return this._http.post(url, data);
  }

  //Verificar activation code
  verify(email, code) {

    let url = `${this._url}auth/verify`;

    let content = {
      email: email,
      code: code,
    };

    return this._http.post(url, content);
  }


}
