import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs/Rx";
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class NFTService {

  _url:string = this._config.host;

  _nft:any = null;

  constructor(private _http: HttpClient, private _config:ConfigService) { }

  get() {

    //let url = `assets/data/nft.json`;
    let url = this._url + `nft`;    
    return this._http.get(url).map(data => data);
    
  }

  getNFT() {
    return this._nft;
  }

  setNFT(nft) {
    this._nft = nft;
  }

  getNFTMarket(timespan?){

    if (!timespan)
      timespan = 'top24h';

    let url = `assets/data/magic-eden.json`;  
    return this._http.get(url).map(data => data[timespan]);
  }


}
