import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs/Rx";
import { time } from 'console';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class MarketService {

  BASE: string = 'https://api.coingecko.com/api/v3/';

  _url:string = this._config.host;

  CURRENCY: string = 'USD';
  PAGE_SIZE: number = 200;

  _crypto: any = null;

  constructor(private _http: HttpClient, private _config: ConfigService) { }


  setCrypto(crypto: any) {
    this._crypto = crypto;
  }

  getCrypto() {
    return this._crypto;
  }


  get() {

    let url = `${this.BASE}coins/markets?vs_currency=${this.CURRENCY}&order=market_cap_desc&per_page=${this.PAGE_SIZE} + '&page=1&sparkline=false`;
    return this._http.get(url).map(data => data);
  }

  getCoins(coins) {
    let strCoins = coins.join(',');
    let url = `${this.BASE}coins/markets?vs_currency=${this.CURRENCY}&order=market_cap_desc&per_page=${this.PAGE_SIZE} + '&page=1&sparkline=false&ids=${strCoins}`;
    return this._http.get(url).map(data => data);
  }

  getCryptoDetails(crypto: string) {

    //let url = `${this.BASE}coins/${crypto.toLowerCase()}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=true`;
    let url = `${this._url}market/crypto/${crypto}`;
    return this._http.get(url).map(data => data);
  }

  getCryptoPrice(crypto, timestamp) {    
    let url = `${this._url}market/price/${crypto}/${timestamp}`;
    return this._http.get(url).map(data => data);

  }

  getBTCPrice(timeframe) {
    let url = this._url + `market/` + timeframe;
    return this._http.get(url).map(data => data);
  }

  getCryptoMarket(page) {
    let url = this._url + `market/crypto/page/` + page;
    return this._http.get(url).map(data => data);
  }
  
  getPortfolio() {
    let url = this._url + `market/portfolio`;
    return this._http.get(url).map(data => data);
  }

  getFavs() {
    let url = this._url + `market/favs`;
    return this._http.get(url).map(data => data);
  }


  getMarketCap() {
    let url = `assets/data/mcap.json`;
    return this._http.get(url).map(data => data);
  }

  
  getBTCHeatmap() {
    let url = this._url + `market/heatmap/btc`;
    return this._http.get(url).map(data => data);
  }

  getFear(days = null) {
    let url = this._url + `market/fear`;

    if (days)
      url += "/" + days;

    return this._http.get(url).map(data => data);
  }

  getAllCoins() {
    let url = this._url + `market/coins`;
    return this._http.get(url).map(data => data);
  }

  searchCoin(term) {
    let url = this._url + `market/coins/search/${term}`;
    return this._http.get(url).map(data => data);
  }


}
