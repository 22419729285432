import { Injectable } from '@angular/core';

import { Router, CanActivate } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()

export class AuthGuardService implements CanActivate {

    constructor(public _auth: AuthService, public _router: Router) { } 
    
    canActivate(): boolean  {

      if (!this._auth.isValid) {

        console.warn("AUTHGUARD: NO VALIDO");

        this._router.navigate(['/auth/login']);

        return false;
      }

      return true;
    }

}