import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs/Rx";
import { ConfigService } from './config.service';
import { warn } from 'console';

@Injectable({
  providedIn: 'root'
})

export class PortfolioService {

_url:string = this._config.host;

  _asset: any = null;

  _wallet: any = null;

  constructor(private _http: HttpClient, private _config:ConfigService) { }

  get() {
    let url = this._url + `portfolio`;
    return this._http.get(url).map(data => data);
  }

  getEvolution(id) {
    let url = this._url + `portfolio/${id}/evolution`;
    return this._http.get(url).map(data => data);
  }

  getValues() {
    let url = `assets/data/portfolio-value.json`;
    return this._http.get(url).map(data => data);
  }

  getOverview() {
    let url = this._url + `portfolio/overview`;
    return this._http.get(url).map(data => data);
  }

  setAsset(asset: any) {
    this._asset = asset;
  }

  getAsset() {
    return this._asset;
  }

  setWallet(wallet:any) {
    this._wallet=wallet;
  }

  getWallet() {
    return this._wallet;
  }

  getAssetDetails(assetId) {
    //let url = `assets/data/asset_details.json`;
    let url = `${this._url}portfolio/asset/${assetId}`;
    return this._http.get(url).map(data => data);    
  }

  getAssetEvolution(assetId) {
    let url = `${this._url}portfolio/asset/${assetId}/evolution`;
    return this._http.get(url).map(data => data);    
  }

  addAsset(portfolioId, asset) {
    let url = `${this._url}portfolio/${portfolioId}/asset`;
    return this._http.post(url, asset).map(data => data);
  }

  deleteAsset(assetId) {
    let url = `${this._url}portfolio/asset/${assetId}`;
    return this._http.delete(url).map(data => data);
  }

  addTransaction(assetId, transaction) {
    let url = `${this._url}portfolio/asset/${assetId}`;
    return this._http.post(url, transaction).map(data => data);
  }

  deleteTransaction(transactionId) {
    let url = `${this._url}portfolio/transaction/${transactionId}`;
    return this._http.delete(url).map(data => data);
  }
  
  getWallets() {
    let url = `${this._url}portfolio/wallets`;
    return this._http.get(url).map(data => data);    
  }

  getWalletsDetails() {
    let url = `${this._url}portfolio/wallets/detail`;
    return this._http.get(url).map(data => data);    
  }

  getWalletDetails(wallet) {
    let url = `${this._url}portfolio/wallet/${wallet}`;
    return this._http.get(url).map(data => data);    
  }

}
