import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private API_URL = environment.API

  constructor() { }

  get host() {
    return this.API_URL; 
  }

}
